function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/logistics-officer/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const LogisticsOfficerRoutes = [
  {
    path: '/logistics-officer/dashboard',
    component: view('Dashboard'),
    name: 'logistics-officer.dashboard',
    meta: {
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-officer/repairs/pending',
    component: view('Processing'),
    name: 'logistics-officer.repairs.pending',
    meta: {
      breadcrumb: [
        {
          text: 'Repairs And Maintenace Requests'
        },
        {
          text: 'Pending',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-officer/approvals/pending',
    component: view('Pending'),
    name: 'logistics-officer.approvals.pending',
    meta: {
      breadcrumb: [
        {
          text: 'Repairs And Maintenace Approvals'
        },
        {
          text: 'Approved',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-officer/approvals/histories/:status?',
    component: view('Histories'),
    name: 'logistics-officer.approvals.histories',
    meta: {
      breadcrumb: [
        {
          text: 'Repairs And Maintenace Approvals'
        },
        {
          text: 'Histories',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-officer/maintenances/vehicle-categories',
    component: view('VehicleCategories'),
    name: 'logistics-officer.maintenances.vehicle-categories',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Vehicle Categories',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-officer/maintenances/vehicles',
    component: view('Vehicles'),
    name: 'logistics-officer.maintenances.vehicles',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Vehicles',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-officer/maintenances/suppliers',
    component: view('Suppliers'),
    name: 'logistics-officer.maintenances.suppliers',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Suppliers',
          active: true
        }
      ]
    }
  }
]

export default LogisticsOfficerRoutes
