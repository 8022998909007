function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/admin/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const AdminRoutes = [
  {
    path: '/admin/dashboard',
    component: view('Dashboard'),
    name: 'admin.dashboard',
    meta: {
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/accounts/users',
    component: view('UserAccounts'),
    name: 'admin.accounts.users',
    meta: {
      breadcrumb: [
        {
          text: 'Accounts'
        },
        {
          text: 'Users',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/accounts/employees',
    component: view('EmployeeAccounts'),
    name: 'admin.accounts.employees',
    meta: {
      breadcrumb: [
        {
          text: 'Accounts'
        },
        {
          text: 'Employees',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/maintenances/companies',
    component: view('Companies'),
    name: 'admin.maintenances.companies',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Companies',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/maintenances/organizations',
    component: view('Organizations'),
    name: 'admin.maintenances.organizations',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Organizations',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/maintenances/positions',
    component: view('Positions'),
    name: 'admin.maintenances.positions',
    meta: {
      breadcrumb: [
        {
          text: 'Maintenances'
        },
        {
          text: 'Positions',
          active: true
        }
      ]
    }
  }
]

export default AdminRoutes
