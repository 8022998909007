import ApiService from '@/services/core/api.service'

const MaintenanceService = {
  async get (query) {
    return ApiService.get(`logistics-manager/approvals?${query}`)
  },

  async histories (query) {
    return ApiService.get(`logistics-manager/reports/request-histories?${query}`)
  },

  async post (payload) {
    return ApiService.post('logistics-manager/approvals', payload)
  },

  async put (payload) {
    return ApiService.put(`logistics-manager/approvals/${payload.id}`, payload)
  }
}

export default MaintenanceService
