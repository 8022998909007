import ApiService from '@/services/core/api.service'

const AttachmentService = {
  async preview (filePath, fileName) {
    return ApiService.get(`shared/attachments/preview?file_path=${filePath}&file_name=${fileName}`)
  },

  async retrieve (maintenanceID, attachmentID) {
    return ApiService.get(`shared/attachments/retrieve?maintenance=${maintenanceID}&attachment=${attachmentID}`)
  },

  async retrieveCompanyLogo () {
    return ApiService.get(`shared/attachments/retrieve-company-logo?branding_path=${process.env.VUE_APP_COMPANY_LOGO || ''}`)
  },

  async list (maintenanceID) {
    return ApiService.get(`shared/attachments/list?maintenance=${maintenanceID}`)
  }
}

export default AttachmentService
