function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/senior-mechanic/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const SeniorMechanicRoutes = [
  {
    path: '/senior-mechanic/dashboard',
    component: view('Dashboard'),
    name: 'senior-mechanic.dashboard',
    meta: {
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true
        }
      ]
    }
  },
  {
    path: '/senior-mechanic/approvals/pending',
    component: view('Pending'),
    name: 'senior-mechanic.approvals.pending',
    meta: {
      breadcrumb: [
        {
          text: 'Repairs And Maintenace Approvals'
        },
        {
          text: 'Pending',
          active: true
        }
      ]
    }
  },
  {
    path: '/senior-mechanic/approvals/histories/:noted_by_status?',
    component: view('Histories'),
    name: 'senior-mechanic.approvals.histories',
    meta: {
      breadcrumb: [
        {
          text: 'Repairs And Maintenace Approvals'
        },
        {
          text: 'Histories',
          active: true
        }
      ]
    }
  }
]

export default SeniorMechanicRoutes
