import ApiService from '@/services/core/api.service'

const MaintenanceService = {
  async get (query) {
    return ApiService.get(`user/requests?${query}`)
  },

  async histories (query) {
    return ApiService.get(`user/reports/request-histories?${query}`)
  },

  async post (payload) {
    return ApiService.post('user/requests', payload)
  },

  async put (payload) {
    return ApiService.put(`user/requests/${payload.id}`, payload)
  },

  async cancel (payload) {
    return ApiService.put(`user/actions/cancel-reservation/${payload.id}`, payload)
  }
}

export default MaintenanceService
