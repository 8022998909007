import RAdmin from './admin.routes'
import RPurchasing from './purchasing.routes'
import RLogisticsManager from './logistics-manager.routes'
import RLogisticsOfficer from './logistics-officer.routes'
import RSeniorMechanic from './senior-mechanic.routes'
import RUser from './user.routes'
import RShared from './shared.routes'

const allRoutes = []

export default allRoutes.concat(
  RShared,
  RAdmin,
  RPurchasing,
  RLogisticsManager,
  RLogisticsOfficer,
  RSeniorMechanic,
  RUser
)
