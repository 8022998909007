import ApiService from '@/services/core/api.service'

const MaintenanceService = {
  async get (query) {
    return ApiService.get(`purchasing/cash-advances?${query}`)
  },

  async histories (query) {
    return ApiService.get(`purchasing/reports/request-histories?${query}`)
  },

  async exports (query) {
    return ApiService.get(`purchasing/exports/request-histories?${query}`)
  },

  async post (payload) {
    return ApiService.post('purchasing/cash-advances', payload)
  },

  async put (payload) {
    return ApiService.put(`purchasing/cash-advances/${payload.id}`, payload)
  }
}

export default MaintenanceService
