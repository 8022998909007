function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/logistics-manager/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const LogisticsManagerRoutes = [
  {
    path: '/logistics-manager/dashboard',
    component: view('Dashboard'),
    name: 'logistics-manager.dashboard',
    meta: {
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-manager/approvals/pending',
    component: view('Pending'),
    name: 'logistics-manager.approvals.pending',
    meta: {
      breadcrumb: [
        {
          text: 'Repairs And Maintenaces Approvals'
        },
        {
          text: 'Pending Requests',
          active: true
        }
      ]
    }
  },
  {
    path: '/logistics-manager/approvals/histories/:approved_by_status?',
    component: view('Histories'),
    name: 'logistics-manager.approvals.histories',
    meta: {
      breadcrumb: [
        {
          text: 'Repairs And Maintenaces Approvals'
        },
        {
          text: 'Histories',
          active: true
        }
      ]
    }
  }
]

export default LogisticsManagerRoutes
