function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/purchasing/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const PurchasingRoutes = [
  {
    path: '/purchasing/dashboard',
    component: view('Dashboard'),
    name: 'purchasing.dashboard',
    meta: {
      breadcrumb: [
        {
          text: 'Dashboard',
          active: true
        }
      ]
    }
  },
  {
    path: '/purchasing/approvals/pending',
    component: view('Pending'),
    name: 'purchasing.approvals.pending',
    meta: {
      breadcrumb: [
        {
          text: 'Repairs And Maintenace Requests'
        },
        {
          text: 'Pending',
          active: true
        }
      ]
    }
  },
  {
    path: '/purchasing/approvals/histories/:purchased_by_status?',
    component: view('Histories'),
    name: 'purchasing.approvals.histories',
    meta: {
      breadcrumb: [
        {
          text: 'Repairs And Maintenace Requests'
        },
        {
          text: 'Histories',
          active: true
        }
      ]
    }
  }
]

export default PurchasingRoutes
