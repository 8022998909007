<template>
  <div
    id="app"
    no-enforce-focus
  >
    <vue-ins-progress-bar />
    <component :is="layout">
      <router-view />
    </component>
    <div
      id="scroll-to-top"
      class="scrolltop text-center"
    >
      <i class="ri-arrow-up-s-fill" />
    </div>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'

const LayoutMain = () => import('@/components/layouts/MainLayout.vue')
const LayoutAuth = () => import('@/components/layouts/AuthorizationLayout.vue')
const LayoutBlank = () => import('@/components/layouts/BlankLayout.vue')

export default {
  name: 'App',
  components: {
    LayoutMain,
    LayoutAuth,
    LayoutBlank
  },
  metaInfo () {
    const appName = process.env?.VUE_APP_TITLE || ''
    return {
      title: appName,
      titleTemplate: `%s · ${appName}`
    }
  },
  computed: {
    layout () {
      if (this.$route.meta.layout === 'auth') return 'layout-auth'
      if (this.$route.meta.layout === 'sso') return 'layout-blank'

      return 'layout-main'
    }
  },
  mounted () {
    this.$insProgress.finish()
    core.mainIndex()
  }
}
</script>
<style lang="scss">
  #modal-preview-print .modal-lg {
    width:100vw;
    max-width: none;
    min-height: 100vh;
    margin: 0
  }

  #modal-preview-print .modal-content {
    min-height: 100vh;
  }
  @import "@/assets/scss/style.scss";
</style>
